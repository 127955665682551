import React, {Component} from 'react';
import {Carousel, Image} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import ReactPlayer from 'react-player'
import {API, graphqlOperation} from "@aws-amplify/api";
import {listProducts, listYoutubeVideos} from "../../../graphql/queries";
import {Storage} from "aws-amplify";
import Fullscreen from "react-full-screen";
import {withAuthenticator} from "@aws-amplify/ui-react";

const BADPRODUCTS = [
    "1110007439",
    "1110007439",
    "1110010162",
    "1110010161",
    "1110009805",
    "1110006982",
    "1110000268",
];

class SlideShowFullScreen extends Component {
    state = {
        time: 0,
        start: 0,
        lastSwap: 0,
        showPhoto: true,
        showVideo: false,
        products: [],
        youtubeVideos: [],
        currentVideo: "",
        currentVideoIndex: 0,
        finish: false,
        currentVideoPlayer: "",
        isFull: false,
        lastShownVideoIndex: 0
    };

    componentDidMount = async () => {
        this.startTimer();
        await this.fetchProducts();
        await this.fetchVideos();
        await this.filterOutBadProducts();
        console.log(this.state.youtubeVideos);
        this.setState({
            finished: true,
            currentVideoPlayer: (<ReactPlayer url={this.state.youtubeVideos[0].url.split("&list=")[0]}
                                              playing={true}
                                              controls={true}
                                              height={'100%'}
                                              width={'100%'}
                                              onEnded={() => this.setState({
                                                  showPhoto: true,
                                                  showVideo: false,
                                                  lastSwap: 0
                                              })}/>)
        })
    };

    goFull = () => {
        this.setState({isFull: true});
    };

    fetchVideos = async () => {
        try {
            const videoData = await API.graphql(graphqlOperation(listYoutubeVideos));
            const videos = videoData.data.listYoutubeVideos.items;
            this.setState({
                youtubeVideos: videos,
            });
        } catch (err) {
            console.log("error fetching videos");
            console.log(err);
        }
    };

    fetchProducts = async () => {
        try {
            const images = await Storage.list("images");
            console.log(images);
            const productData = await API.graphql(graphqlOperation(listProducts));
            console.log(productData);
            const products = productData.data.listProducts.items;
            this.setState({
                products: products,
                all_products: products
            });
            await this.update_products_with_images(products);
        } catch (err) {
            console.log("error fetching products");
            console.log(err);
        }
    };

    update_products_with_images = async (products) => {
        let new_products = [];
        products.map(async (product) => {
            let image_url = await Storage.get("images/" + product.articleNumber + ".jpg");
            this.setState({
                products: [...new_products, {...product, image: image_url}],
                all_products: [...new_products, {...product, image: image_url}]
            });
            new_products.push({...product, image: image_url})
        });
    };

    filterOutBadProducts = async () => {
        let final_products = [];

        this.state.products.forEach(product => {
            if (!BADPRODUCTS.includes(product.articleNumber)) {
                final_products.push(product)
            }
        });

        this.setState({
            products: final_products,
            all_products: final_products
        })
    };

    startTimer = () => {
        this.setState({
            time: this.state.time,
            start: Date.now()
        });
        this.timer = setInterval(() => this.setState({
            time: Date.now() - this.state.start,
            lastSwap: this.state.lastSwap + 1
        }), 1000);
        console.log("start")
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.lastSwap >= 20 && this.state.showPhoto) {
            let newVideoIndex = Math.floor(Math.random() * this.state.youtubeVideos.length)

            while (newVideoIndex === this.state.oldVideoIndex) {
                newVideoIndex = Math.floor(Math.random() * this.state.youtubeVideos.length)
            };

            var randomVideo = this.state.youtubeVideos[newVideoIndex];
            var randomProducts = [
                this.state.all_products[Math.floor(Math.random() * this.state.all_products.length)],
                this.state.all_products[Math.floor(Math.random() * this.state.all_products.length)],
                this.state.all_products[Math.floor(Math.random() * this.state.all_products.length)]
            ];
            this.setState({
                lastSwap: 0,
                showPhoto: !this.state.showPhoto,
                showVideo: !this.state.showVideo,
                products: randomProducts,
                currentVideoPlayer: (<ReactPlayer url={randomVideo.url.split("&list=")[0]}
                                                  playing={true}
                                                  controls={true}
                                                  height={'100%'}
                                                  width={'100%'}
                                                  onEnded={() => this.setState({
                                                      showPhoto: true,
                                                      showVideo: false,
                                                      lastSwap: 0
                                                  })}/>)
            });

        }

        if (this.state.lastSwap >= 3) {
        }
    }

    render() {
        return this.state.isFull ? (
            <Fullscreen
                enabled={this.state.isFull}
                onChange={isFull => this.setState({isFull})}
            >
                <header id="hero-overlay" af-el="video-play-overlay" className="hero-overlay">
                    <div className="div-block-4">
                        <div className="div-block-6" style={{height: "100vh"}}>
                            {this.state.finished ? (
                                    <>
                                        {this.state.showPhoto ?
                                            <Carousel
                                                style={{width: "200vh", height: "100vh", pause: "false", controls: "false", marginLeft: "22%"}}
                                                pause={false} controls={false} fade={true} indicators={false}>
                                                {this.state.products.map(product => {
                                                    return product.image ? (
                                                            <Carousel.Item
                                                                style={{margin: "auto", width: "100vh", height: "100vh"}}
                                                                key={product.articleNumber}
                                                            >
                                                                <Image
                                                                    className="d-block w-100"
                                                                    style={{width: "80vh", height: "80vh"}}
                                                                    src={product.image}
                                                                    alt={product.title}
                                                                    fluid
                                                                />

                                                                <Carousel.Caption style={{
                                                                    color: 'white',
                                                                    backgroundColor: "#A34511",
                                                                    border: '20px', 
                                                                    fontSize: '20px',
                                                                    marginBottom: "60px"                                                                                                
                                                                }}>
                                                                    <h3>{product.title}</h3>
                                                                    <p>€ {product.price}</p>
                                                                </Carousel.Caption>
                                                            </Carousel.Item>
                                                        )
                                                        : ""
                                                })}
                                            </Carousel>
                                            : ""
                                        }

                                        {this.state.showVideo ? this.state.currentVideoPlayer : ""}
                                    </>
                                )
                                : "Laden..."
                            }
                        </div>
                    </div>
                </header>
            </Fullscreen>
        ) : <>
            <header id="hero-overlay" af-el="video-play-overlay" className="hero-overlay">
                <div className="div-block-4">
                    <p style={{zIndex: 100}} onClick={() => this.props.history.push('/gebruiker/slideshow')}
                       className="button-2 w-button">SLUITEN</p>
                    <p onClick={this.goFull} style={{zIndex: 100, marginRight: "20%"}}
                       className="button-2 fullscreen w-button">Full
                        Screen</p>
                    <div className="div-block-6" style={{height: "100vh"}}>
                        {this.state.finished ? (
                                <>
                                    {this.state.showPhoto ?
                                        <Carousel
                                            style={{width: "200vh", height: "100vh", pause: "false", controls: "false", marginLeft: "22%"}}
                                            pause={false} controls={false} fade={true} indicators={false}>
                                            {this.state.products.map(product => {
                                                return product.image ? (
                                                        <Carousel.Item style={{margin: "auto", width: "100vh", height: "100vh"}}
                                                                       key={product.articleNumber}
                                                        >
                                                            <img
                                                                className="d-block w-100"
                                                                src={product.image}
                                                                style={{width: "80vh", height: "80vh"}}
                                                                alt={product.title}
                                                            />

                                                            <Carousel.Caption style={{
                                                                color: 'white',
                                                                backgroundColor: "#A34511",
                                                                border: '20px',
                                                                fontSize: '20px',
                                                                marginBottom: "60px"                                                
                                                            }}>
                                                                <h3>{product.title}</h3>
                                                                <p>€ {product.price}</p>
                                                            </Carousel.Caption>
                                                        </Carousel.Item>
                                                    )
                                                    : ""
                                            })}
                                        </Carousel>
                                        : ""
                                    }

                                    {this.state.showVideo ? this.state.currentVideoPlayer : ""}
                                </>
                            )
                            : "Laden..."
                        }
                    </div>
                </div>
            </header>
        </>
    }
}

export default withAuthenticator(withRouter(SlideShowFullScreen));