import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

import './Footer.css';
class Footer extends Component {
    state = {};

    componentDidMount() {

    };

    render() {
        return (
            <footer className="footer"></footer>
        )
    }
}


export default withRouter(Footer);