/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getYoutubeVideo = /* GraphQL */ `
  query GetYoutubeVideo($id: ID!) {
    getYoutubeVideo(id: $id) {
      id
      title
      url
      s3Location
      dateAdded
      createdAt
      updatedAt
    }
  }
`;
export const listYoutubeVideos = /* GraphQL */ `
  query ListYoutubeVideos(
    $filter: ModelYoutubeVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYoutubeVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        url
        s3Location
        dateAdded
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUploadedExcel = /* GraphQL */ `
  query GetUploadedExcel($id: ID!) {
    getUploadedExcel(id: $id) {
      id
      location
      createdAt
      updatedAt
    }
  }
`;
export const listUploadedExcels = /* GraphQL */ `
  query ListUploadedExcels(
    $filter: ModelUploadedExcelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploadedExcels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      articleNumber
      price
      imageLocation
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        articleNumber
        price
        imageLocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
