import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {Row, Col} from "react-bootstrap";

import Layout from "./hoc/Layout/Layout";
import homepage from "./components/Generic/homepage/homepage";
import inloggen from "./components/Generic/inloggen/inloggen";
import Footer from "./components/Generic/UI/Footer/Footer";
import Header from "./components/Generic/UI/Header/Header";
import youtube from "./components/Generic/youtube/youtube";
import video from "./components/Generic/video/video";
import producten from "./components/Generic/producten/producten";
import SlideShow from "./components/Generic/SlideShow/SlideShow";

import './assets/css/webflow.css';
import './assets/css/normalize.css';
import './assets/css/general.css';
import './assets/css/mart-articles-website.webflow.css';
import SlideShowFullScreen from "./components/Generic/SlideShow/SlideshowFullScreen";

class App extends Component {
    render() {
        let routes = (
            <>
                <Header/>
                <div>
                    {/*<NavigationBarView />*/}

                    <Row>
                        <Col md={{span: 6, offset: 3}}>
                            <TransitionGroup>
                                <CSSTransition key={this.props.location.key} timeout={500} classNames="pageSlider"
                                               mountOnEnter={true} unmountOnExit={true}>
                                    <Switch location={this.props.location}>
                                        <Route exact path="/" component={homepage}/>

                                        {/* Auth required paths */}
                                        <Route exact path="/inloggen" component={inloggen}/>
                                        <Route exact path="/gebruiker/youtube-videos" component={youtube}/>
                                        <Route exact path="/gebruiker/video" component={video}/>
                                        <Route exact path="/gebruiker/slideshow" component={SlideShow}/>
                                        <Route exact path="/gebruiker/producten" component={producten}/>
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        </Col>
                    </Row>

                    <Footer/>
                </div>
            </>
        );

        return (
            <div>
                <Switch location={this.props.location}>
                    <Route exact path="/gebruiker/slideshowFullScreen"
                           component={SlideShowFullScreen}/>
                </Switch>
                <Layout>{routes}</Layout>
            </div>
        );
    }
}

export default withRouter(App);
