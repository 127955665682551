/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://kgyqvgfrsbayvpx2x3fvq6js6y.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "4taktrestapi",
            "endpoint": "https://r8crarmsdl.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:a56f45c8-874c-4588-920d-a005e40c23d4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_y10CKiZsF",
    "aws_user_pools_web_client_id": "avhhg3qcau56q2bupoeb8t60i",
    "oauth": {},
    "aws_user_files_s3_bucket": "4taktwinkel-storage91056-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
