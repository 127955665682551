import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { deleteProduct } from "./../../../graphql/mutations";
import { listProducts } from "./../../../graphql/queries";
import { Storage } from "aws-amplify";
import { Modal, Button, InputGroup, Form, Spinner } from 'react-bootstrap';
import PeoplePlatformImage
    from './../../../assets/images/illo-people-platform-083b0fcab67e4afc6cb36e94f1ce582e5560ad30a10bc360c25e5a2c721527e3.png.png'

class Producten extends Component {
    state = {
        uploaded: false,
        loading: false,
        error: false,
        uploadedExcelLocation: "",
        products: [],
        response: "",
        showModal: false,
        loadedProducts: 25
    };

    componentDidMount() {
        this.fetchProducts();
    }

    uploadFile = async (e) => {
        this.setState({ loading: true });
        const file = e.target.files[0];
        const bucket = "excel-sheet-products/";
        const fileName = Date.now() + ".xls";
        Storage.put(bucket + fileName, file).then((result) => {
            const s3Location = result;
            API.post("4taktrestapi", "/excel-uploader", { body: { s3_location: s3Location } }).then((response) => {
                console.log(response);
                this.fetchProducts();
                this.setState({ loading: false, error: false, uploaded: true, response: response });
            });
        });
    };

    fetchProducts = async () => {
        try {
            const images = await Storage.list("images");
            console.log(images);
            const productData = await API.graphql(graphqlOperation(listProducts, {limit: this.state.loadedProducts}));
            console.log(productData);
            const products = productData.data.listProducts.items;
            console.log("Fetched products");
            console.log(products);
            this.setState({
                products: products,
            });
            this.update_products_with_images(products);
            var oldNextToken = productData.data.listProducts.nextToken
            while (oldNextToken & this.state.products.length < this.state.loadedProducts) {
                const productData = await API.graphql(graphqlOperation(listProducts, {nextToken: oldNextToken, limit: this.state.loadedProducts}));
                console.log(productData.data.listProducts.nextToken)
                console.log(oldNextToken !== productData.data.listProducts.nextToken);
                oldNextToken = productData.data.listProducts.nextToken;
                console.log(productData);
                const products = productData.data.listProducts.items;
                this.setState({
                    products: [...this.state.products, ...products],
                });
            }
        } catch (err) {
            console.log("error fetching products");
            console.log(err);
        }
    };

    update_products_with_images = async (products) => {
        let new_products = []
        products.map(async (product) => {
            let image_url = await Storage.get("images/" + product.articleNumber + ".jpg")
            console.log(image_url)
            this.setState({
                products: [...new_products, { ...product, image: image_url }]
            })
            new_products.push({ ...product, image: image_url })
        })
    };

    deleteProduct = async (product) => {
        try {
            this.setState({ loading: true });
            console.log(product);
            await API.graphql(graphqlOperation(deleteProduct, { input: { id: product.id } }));
            this.setState({ loading: false });
            this.fetchProducts();
        } catch (err) {
            console.log(err);
            console.log("error deleting product");
        }
    };

    deleteAllProducts = async () => {
        this.setState({ loading: true });
        await Promise.all(this.state.products.forEach(async (product) => {
            try {
                console.log(product);
                await API.graphql(graphqlOperation(deleteProduct, { input: { id: product.id } }));
                this.fetchProducts();
            } catch (err) {
                console.log(err);
                console.log("error deleting product");
            }
        }));
        this.setState({ loading: false });

    }

    render() {
        return !this.state.loading ? (
            <main af-el="production-main-element" className="main">
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nieuwe excel met producten uploaden</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Op deze pagina voeg je producten toe die te zien zullen zijn in de video. Je kunt producten
                        toevoegen door een excel bestand te uploaden.
                            {/* Upload new products */}
                        <>
                            {!this.state.loading ? (
                                <>
                                    <h3>Nieuwe productenlijst uploaden</h3>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Excel bestand</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.File
                                            id="custom-file"
                                            label="Custom file input"
                                            onChange={async (evt) => {
                                                await this.uploadFile(evt);
                                            }}
                                            custom
                                        />
                                    </InputGroup>
                                    {this.state.response !== "" ? <p>{this.state.response}</p> : null}
                                </>
                            ) : (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )}
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>Close</Button>
                        <Button variant="primary" onClick={() => this.setState({ showModal: false })}>Save changes</Button>
                    </Modal.Footer>
                </Modal>

                <div af-el="production-content-box" className="introductiontextbox w-container">
                    <h4 className="heading-2"><strong className="bold-text">Dit zijn jouw huidige
                            producten </strong><br />
                    </h4>
                    <div>Hieronder volgt een overzicht van de huidige producten die voorbij komen in jouw producten
                    slideshow. Je kunt nieuwe producten handmatig toevoegen door op de toevoegen knop te
                            drukken.<br />‍
                        </div>
                    <Button onClick={() => this.setState({ showModal: true })}>Excel uploaden</Button>
                    <Button style={{ marginLeft: "10px" }} onClick={() => this.deleteAllProducts()} variant="danger">
                        Verwijder alle producten
                    </Button>
                    <hr/>
                    <ul className="w-list-unstyled">
                        {this.state.products.slice(0, this.state.loadedProducts).map((product) => {
                            return (
                                <li key={product.articleNumber} style={{marginTop: "5px"}}>
                                    <div className="columns w-row">
                                        <div className="column-2 w-col w-col-2"><img
                                            src={product.image}
                                            loading="lazy" width="75" height="50" alt="" /></div>
                                        <div className="w-col w-col-4">
                                            <div className="text-block-2">{product.title ? product.title : "N/A"}</div>
                                        </div>
                                        <div className="w-col w-col-2">
                                            <div className="text-block-2">€{product.price}</div>
                                        </div>
                                        <div className="column w-col w-col-3">
                                            <div className="text-block-2">{product.articleNumber}</div>
                                        </div>
                                        <div className="column-3 w-col w-col-1">
                                            <p onClick={() => this.deleteProduct(product)}
                                                style={{ width: "150px" }}
                                                className="button-2 delete _2 w-button">Verwijderen</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                        <hr/>
                        <p style={{marginTop: "15px"}}>{this.state.loadedProducts} producten geladen</p>
                        <p onClick={() => {
                            this.setState({ loadedProducts: this.state.loadedProducts + 25 });
                            this.fetchProducts();
                        }}>Klik hier om meer producten te laden..</p>
                    </ul>
                    <img
                        src={PeoplePlatformImage}
                        loading="lazy" sizes="(max-width: 767px) 100vw, 677.40625px"
                        alt="" className="image" />
                </div>
            </main>
        ) :
            <div>Loading</div>
    }
}

export default withAuthenticator(withRouter(Producten));
