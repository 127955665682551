// Third party components
import React, { Component } from 'react';

// Custom components
import Aux from '../Auxo/Auxo';

// CSS components
import classes from './Layout.css';

class layout extends Component {
    render() {
        return (
            <Aux>
                <main className={classes.Content}>
                    {this.props.children}
                </main>
            </Aux>
        );
    }
}

export default layout;