import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import PeoplePlatformImage
    from './../../../assets/images/illo-people-platform-083b0fcab67e4afc6cb36e94f1ce582e5560ad30a10bc360c25e5a2c721527e3.png.png'

class homepage extends Component {
    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <main af-el="homepage-intro-element" className="main">
                <div className="introductiontextbox w-container">
                    <h4 className="heading-2"><strong className="bold-text">Welkom bij jouw nieuwe advertentie
                        video</strong><br/></h4>
                    <h2 className="heading-3">Bedrijfsvideo-generen maakt het makkelijk om jouw <p
                        className="bigtextlink">in-winkel</p>
                        <p className="bigtextlink">video</p>
                        <p className="bigtextlink">te genereren</p> en <p
                            className="bigtextlink">onderhouden </p>
                    </h2>
                    <img
                        src={PeoplePlatformImage}
                        loading="lazy" sizes="(max-width: 767px) 100vw, 677.4166259765625px"
                        alt="" className="image"/>
                </div>
            </main>
        );
    }
}

export default withRouter(homepage);
