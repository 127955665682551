import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import PeoplePlatformImage
    from "./../../../assets/images/illo-people-platform-083b0fcab67e4afc6cb36e94f1ce582e5560ad30a10bc360c25e5a2c721527e3.png.png";
import {withAuthenticator} from "@aws-amplify/ui-react";

class SlideShow extends Component {
    render() {
        return (
            <main af-el="slideShowMainContent" className="main">
                <div af-el="video-play-page" className="introductiontextbox w-container">
                    <h4 className="heading-2"><strong className="bold-text">Hier kun je jouw video&#x27;s
                        afspelen</strong><br/></h4>
                    <div className="text-block-3">Deze pagina maakt het mogelijk om een nieuwe slideshow te starten.
                    </div>
                    <p onClick={() => this.props.history.push('/gebruiker/slideshowFullScreen')}
                       className="button-4 w-button">Starten</p>
                    <img
                        src={PeoplePlatformImage}
                        loading="lazy" sizes="(max-width: 767px) 100vw, 677.40625px"
                        alt="" className="image"/>
                </div>
            </main>
        )
    }
}

export default withAuthenticator(withRouter(SlideShow));