import React, { Component } from "react";
import { Button, Form, DropdownButton, Dropdown, InputGroup } from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { withRouter } from "react-router-dom";
import VideoPlayer from "./../UI/VideoPlayer/VideoPlayer";
import Storage from "@aws-amplify/storage";
import axios from 'axios';

class video extends Component {
  state = {
    videos: [],
    selectedVideo: null,
  };

  componentDidMount() {
    Storage.list("generated_videos", { level: "public" }).then((resp) => {
      this.setState({
        videos: resp.reverse(),
      });
    });
  }

  generateNewVideo = async () => {
    console.log('Clicked')
    const response = await axios({method: "get", url: "https://api.bedrijfsvideo-genereren.nl/generate/video"});
    console.log('Generated')
    const myJson = await response.json(); //extract JSON from the http response
    console.log('Finished')
    console.log(myJson)
    debugger;
  };

  render() {
    return (
      <>
        <br />
        <h2>Video's</h2>
        <p>Op deze pagina selecteer je een video om te tonen, of genereer je een nieuw.</p>
        <h3>Nieuwe video genereren</h3>
        <Form>
          <Button onClick={() => this.generateNewVideo()}>Genereer nieuwe video</Button>
        </Form>

        <h3>Video tonen</h3>

        {/* Video selector */}
        <Form>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>Geselecteerde video</InputGroup.Text>
            </InputGroup.Prepend>
            <DropdownButton
              onSelect={(e) => this.setState({ selectedVideo: e })}
              as={InputGroup.Prepend}
              variant="outline-secondary"
              title={this.state.selectedVideo === null ? "Selecteer een video" : this.state.selectedVideo}
              id="input-group-dropdown-1"
            >
              {this.state.videos.map((video) => {
                return <Dropdown.Item eventKey={video.key}>{video.key}</Dropdown.Item>;
              })}
            </DropdownButton>
          </InputGroup>
        </Form>

        {/* Video player */}
        {this.state.selectedVideo !== null ? <VideoPlayer fileKey={this.state.selectedVideo} fileType={"video/mp4"} /> : null}
        {/* Products in video */}
        <></>
      </>
    );
  }
}

export default withAuthenticator(withRouter(video));
