import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { API } from "aws-amplify";

class inloggen extends Component {
    state = {
        user: null,
        showEditUserInfoModal: false,
        bedrijfsNaam: ""
    };

    componentDidMount() {
        Auth.currentUserInfo().then(user => {
            this.setState({
                user: user,
                bedrijfsNaam: user.attributes['custom:Bedrijf'] ? user.attributes['custom:Bedrijf'] : "Geen"
            })
        });
    }

    storeNewBedrijfsNaam = () => {
        API.post("4taktrestapi", "/users/update/bedrijf", { body: { bedrijfsnaam: this.state.bedrijfsNaam, username: this.state.user.username } }).then((response) => {
            console.log(response);
            this.setState({ showEditUserInfoModal: false });
            window.location.reload(false);
        });
    }


    signOut = () => {
        console.log("Signing out");
        Auth.signOut()
            .then((resp) => {
                console.log(resp);
                this.setState({
                    loggedIn: false,
                });
                window.location.reload(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        return (
            <>

                <Modal show={this.state.showEditUserInfoModal}
                    onHide={() => this.setState({ showEditUserInfoModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bedrijfsgegevens wijzigen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Bedrijfsnaam: </label>
                        <input type={"text"} value={this.state.bedrijfsNaam}
                            onChange={e => this.setState({ bedrijfsNaam: e.target.value })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showEditUserInfoModal: false })}>
                            Sluiten
                        </Button>
                        <Button variant="primary" onClick={() => this.storeNewBedrijfsNaam()}>
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </Modal>
                <br />
                <h2>{this.state.user ? "Ingelogd als " + this.state.user.attributes.email : "Niet ingelogd."}</h2>

                {this.state.user ? (
                    <>
                        <div>
                            <p onClick={() => this.setState({ showEditUserInfoModal: true })}><u>Klik hier om je
                                bedrijfsgegevens te wijzigen</u></p>
                            <ul>
                                <li>Email: {this.state.user.attributes.email}</li>
                                <li>Bedrijf: {this.state.user.attributes['custom:Bedrijf'] ? this.state.user.attributes['custom:Bedrijf'] : "Geen"}</li>
                            </ul>
                        </div>


                        <div style={{ marginTop: "25px" }}>
                            <i>Versie: 0.2 - April 2021</i>
                            <p>
                                <ul>
                                    <li><Link to={"/gebruiker/youtube-videos"}>Klik hier voor het toevoegen van youtube
                                        video's</Link></li>
                                    <li><Link to={"/gebruiker/producten"}>Klik hier voor het toevoegen van
                                        producten</Link>
                                    </li>
                                    <li><Link to={"/gebruiker/video"}>Klik hier voor het tonen van de video's in de
                                        winkel</Link>
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <Button onClick={() => this.signOut()} variant="warning">Uitloggen</Button>
                    </>) : <p>Niet ingelogd?</p>}
            </>
        )
    }
}


export default withAuthenticator(withRouter(inloggen));