import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Auth} from "aws-amplify";

import VideoEditingImage from "./../../../../assets/images/video-editing.png";

class Header extends Component {
    state = {
        loggedIn: false,
    };

    componentDidMount() {
        Auth.currentUserInfo().then((resp) => {
            console.log(resp);
            if (resp !== null) {
                this.setState({
                    loggedIn: true,
                });
            } else {
                this.setState({
                    loggedIn: false,
                });
            }
        });
    }

    componentDidUpdate() {
        Auth.currentUserInfo().then((resp) => {
            console.log(resp);
            if (resp !== null && this.state.loggedIn === false) {
                this.setState({
                    loggedIn: true,
                });
            }
        });
    }

    render() {
        return (
            <div data-collapse="medium" data-animation="default" data-duration="400" role="banner"
                 className="navbar w-nav">
                <div className="w-container">
                    <Link to={"/"}>
                        <p className="w-nav-brand"><img src={VideoEditingImage}
                                                        loading="lazy"
                                                        width="50px"
                                                        sizes="50px"
                                                        alt=""/>
                        </p>
                    </Link>
                    <nav role="navigation" className="w-nav-menu">
                        <Link to={"/gebruiker/producten"}> <p className="nav-link w-nav-link">Producten</p></Link>
                        <Link to={"/gebruiker/youtube-videos"}><p className="nav-link w-nav-link">Youtube</p></Link>
                        <Link to={"/gebruiker/slideshow"}><p className="nav-link w-nav-link">Video</p></Link>
                        <Link to="/inloggen"><p className="login w-nav-link">{!this.state.loggedIn ? "Inloggen": "Voorkeuren"}</p></Link> :
                    </nav>
                    <div className="w-nav-button">
                        <div className="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
