import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { createYoutubeVideo, deleteYoutubeVideo } from "./../../../graphql/mutations";
import { listYoutubeVideos } from "./../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {Table, Modal, Button} from "react-bootstrap"
class youtube extends Component {
    state = {
        videoUrl: "",
        videoTitle: "",
        loading: false,
        error: false,
        uploaded: false,
        showModal: false,
        youtubeVideos: [],
    };

    componentDidMount() {
        this.fetchVideos();
    }

    addVideoToDatabase = async () => {
        try {
            this.setState({ loading: true });
            if (this.state.videoUrl.startsWith("https")) {
                this.setState({ videoUrl: this.state.videoUrl.replace("https", "http") })
            }

            const video = { url: this.state.videoUrl, title: this.state.videoTitle };
            const videoResponse = await API.graphql(graphqlOperation(createYoutubeVideo, { input: video }));
            console.log("created video");
            console.log(videoResponse);

            this.fetchVideos();
            API.post("restapi", "/youtube/download", {
                body: { video_id: videoResponse.data.createYoutubeVideo.id },
                headers: {}
            })
                .then((response) => {
                    // Add your code here
                    console.log(response);
                    this.setState({
                        loading: false,
                        uploaded: true,
                        error: false,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                    this.setState({
                        loading: false,
                        error: true,
                        uploaded: false,
                    });
                });
        } catch (err) {
            console.log("error fetching videos");
            this.setState({
                loading: false,
                error: true,
                uploaded: false,
            });
        }
    };

    fetchVideos = async () => {
        try {
            const videoData = await API.graphql(graphqlOperation(listYoutubeVideos));
            const videos = videoData.data.listYoutubeVideos.items;
            console.log("Fetched videos");
            console.log(videos);
            this.setState({
                youtubeVideos: videos,
            });
        } catch (err) {
            console.log("error fetching videos");
        }
    };

    deleteVideo = async (video) => {
        try {
            this.setState({ loading: true });
            console.log(video);
            await API.graphql(graphqlOperation(deleteYoutubeVideo, { input: { id: video.id } }));
            this.setState({ loading: false });
            this.fetchVideos();
        } catch (err) {
            console.log(err);
            console.log("error delete video");
        }
    };

    render() {
        return !this.state.loading ? (
            <main className="main">
                <div className="introductiontextbox w-container">
                    <h4 className="heading-2"><strong className="bold-text">Dit zijn jouw huidige youtube
                        videos</strong><br /></h4>
                    <div>In de tabel hieronder vind je de huidige Youtube filmpjes die worden gebruikt bij het maken van
                    een
                    nieuwe slideshow. Je kunt hieronder ook nieuwe filmpjes toevoegen of huidige filmpjes
                        verwijderen.<br />‍
                    </div>
                    <p className="button-3 w-button" onClick={() => this.setState({ showModal: true })}>Video toevoegen</p>

                    <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Youtube video toevoegen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Youtube video titel:</label>
                            <input type={"text"} value={this.state.videoTitle} onChange={e => this.setState({ videoTitle: e.target.value })} style={{ width: "100%" }} />
                            <label>Youtube video URL:</label>
                            <input type={"text"} value={this.state.videoUrl} onChange={e => this.setState({ videoUrl: e.target.value })} style={{ width: "100%" }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
                                Sluiten
                            </Button>
                            <Button onClick={() => this.addVideoToDatabase()}>Youtube video toevoegen</Button>
                        </Modal.Footer>
                    </Modal>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Video Titel</th>
                                <th>Toegevoegd</th>
                                <th>Youtube URL</th>
                                <th>Verwijderen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.youtubeVideos.map((video) => {
                                return (
                                    <tr>
                                        <td>{video.title ? video.title : "N/A"}</td>
                                        <td>{video.createdAt.substring(0, 10)}</td>
                                        <td>{video.url.split("&list")[0]}</td>
                                        <td><Button variant={"warning"} onClick={() => this.deleteVideo(video)}>Verwijderen</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </main>
        ) : <div>Loading...</div>;
    }
}

export default withAuthenticator(withRouter(youtube));
