import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ScrollToTop from "react-router-scroll-top";

import "./assets/css/general.css"
import "./assets/css/webflow.css"
import "./assets/css/normalize.css"
import "./assets/css/mart-articles-website.webflow.css"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// import 'bootstrap/dist/css/bootstrap.min.css';

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const options = {
  position: "bottom center",
  timeout: 5000,
  offset: "30px",
  transition: "scale"
};

const app = (
  <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
          <ScrollToTop>
              <App/>
          </ScrollToTop>
      </BrowserRouter>
  </AlertProvider>
);

ReactDOM.render(app, document.getElementById("root"));